export class GetDepartments {
  static readonly type = '[Departments] Get departments list';
}

export class GetEmployeeAideTypes {
  static readonly type = '[AideType] GetAideTypes';
}

export class GetEmployeeHiringStatuses {
  static readonly type = '[HiringStatuses] GetEmployeeHiringStatuses';
}

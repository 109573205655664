import { Action, Selector, State, StateContext } from '@ngxs/store';
import { StaticDataService } from '../../../static-data.service';
import { GetDepartments, GetEmployeeAideTypes, GetEmployeeHiringStatuses } from './departments.actions';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AideType, HiringStatusesModel } from '../../../shared/models/others.models';

interface DepartmentsStateModel {
  list: any[];
  aideTypes: AideType[];
  hiringStatuses: HiringStatusesModel[];
  hiringStatusesFilter: HiringStatusesModel[];
}

export const defaultState: DepartmentsStateModel = {
  list: [],
  aideTypes: [],
  hiringStatuses: [],
  hiringStatusesFilter: [],
};

@State({
  name: 'departments',
  defaults: defaultState,
})
@Injectable()
export class DepartmentsState {
  constructor(private service: StaticDataService) {}

  @Selector()
  static list(state): any[] {
    return state.list;
  }

  @Selector()
  static aideTypes(state): AideType[] {
    return state.aideTypes;
  }

  @Selector()
  static aideTypesFilter(state): AideType[] {
    const noValueItem: AideType = {
      key: '-1',
      name: 'No value',
    };

    return [noValueItem, ...state.aideTypes];
  }

  @Selector()
  static hiringStatuses(state): HiringStatusesModel[] {
    return state.hiringStatuses;
  }

  @Selector()
  static hiringStatusesFilter(state): HiringStatusesModel[] {
    return state.hiringStatusesFilter;
  }

  @Action(GetDepartments)
  getDepartments(ctx: StateContext<DepartmentsStateModel>): Observable<any> {
    return this.service.getDepartments().pipe(tap((data: any[]) => ctx.patchState({ list: data })));
  }

  @Action(GetEmployeeAideTypes)
  getEmployeeAideTypes(ctx: StateContext<DepartmentsStateModel>): Observable<AideType[]> {
    return this.service.getAideTypes().pipe(
      tap((res: AideType[]) => {
        ctx.patchState({
          aideTypes: res,
        });
      }),
    );
  }

  @Action(GetEmployeeHiringStatuses)
  getEmployeeHiringStatuses(ctx: StateContext<DepartmentsStateModel>) {
    return this.service.getEmployeeHiringStatuses().pipe(
      tap((res: HiringStatusesModel[]) => {
        const noValueItem: HiringStatusesModel = {
          key: '-1',
          name: 'No value',
        };
        ctx.patchState({
          hiringStatuses: res,
          hiringStatusesFilter: [noValueItem, ...res],
        });
      }),
    );
  }
}
